import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CategoriasService } from 'src/app/services/categorias.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  /*Icons*/
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;

  formularioCategoria: FormGroup;
  categoriaID = "";
  categoriaForm = false;
  editForm = false;
  arrayCategorias = [];
  currentIndex: any;
  currentId: any;

  constructor(private db: AngularFirestore,
              private formBuilder: FormBuilder, 
              private msj: MensajesService,
              private categoriasSvc: CategoriasService) { }

  ngOnInit(): void {
    this.loadCategorias();
    this.formularioCategoria = this.formBuilder.group({ Nombre: ['', Validators.required] });

  }
  public loadCategorias(){
    this.categoriasSvc.getCategorias().subscribe((categorias: any) => {
      categorias.forEach(categoria => {
        this.arrayCategorias.push({
          data: categoria.data,
          id: categoria.id
        })
      })
    })
    // this.db.collection('Categorias').get().subscribe((categorias) => {
    //   categorias.docs.forEach(categoria => {
    //     this.arrayCategorias.push({
    //       data: categoria.data(),
    //       id: categoria.id
    //     })
    //   })
    // })

  }
  public createCategoria(){
    this.categoriaForm = true;
  }
  public onEdit(index){
    this.currentIndex = index;
    this.categoriaForm = true;
    this.editForm = true;
    this.formularioCategoria.patchValue({
      Nombre: this.arrayCategorias[this.currentIndex].data.name
    })
  }

  public editCategoria(){
    this.arrayCategorias[this.currentIndex].data.name = this.formularioCategoria.value.Nombre;
    // let categorias = {Categorias: this.arrayCategorias};
    // this.db.collection('Categorias').doc(this.arrayCategorias[this.currentIndex].id).update(this.arrayCategorias[this.currentIndex].data)
    // .then(result => {
    //   this.msj.mensajeCorrecto('Correcto', 'Categoria actualizada');
    //   this.categoriaForm = false;
    // })
    // .catch(err => {})
    this.categoriasSvc.updateCategoria(this.arrayCategorias[this.currentIndex].id, this.arrayCategorias[this.currentIndex].data)
    .subscribe(result => {
      this.msj.mensajeCorrecto('Correcto', 'Categoria actualizada');
      this.categoriaForm = false;
    })
  }
  public onDelete(index, id){
    this.currentIndex = index;
    this.currentId = id;
    this.deleteCategoria()

  }

  public deleteCategoria(){
    this.arrayCategorias.splice(this.currentIndex, 1);
    let categorias = {Categorias: this.arrayCategorias};
    this.arrayCategorias.splice(this.currentIndex, 1);
    //let categorias = {Categorias: this.arrayCategorias};
    // this.db.collection('Categorias').doc(this.currentId).delete()
    // .then(result => {
    //   this.msj.mensajeCorrecto('Correcto', 'Categoria eliminada');
    // })
    // .catch(err => {})
    this.categoriasSvc.deleteCategoria(this.currentId)
    .subscribe(result => {
      this.msj.mensajeCorrecto('Correcto', 'Categoria eliminada');

    })

  }
  public addCategoria(){
    // this.db.collection('Categorias').add({name: this.formularioCategoria.value.Nombre, status: 'Active'})
    // // this.db.collection('Parametros').doc(this.categoriaID).update(categorias)
    // .then(result => {
    //   this.arrayCategorias.push({
    //     data: {name: this.formularioCategoria.value.Nombre},
    //     id: result.id
    //   });
    //   this.msj.mensajeCorrecto('Correcto', 'Categoria agregada');
    //   this.formularioCategoria.reset();
    //   this.categoriaForm = false;
    // })
    // .catch(err => {})
    this.categoriasSvc.addCategoria({name: this.formularioCategoria.value.Nombre, status:'Active'})
    .subscribe((result: any) => {
      this.arrayCategorias.push({
        data: {name: this.formularioCategoria.value.Nombre},
        id: result.id
      });
      this.msj.mensajeCorrecto('Correcto', 'Categoria agregada');
      this.formularioCategoria.reset();
      this.categoriaForm = false;
    })
    
  }

  public closeCategoriaForm(){
    this.categoriaForm = false;
  }

}
