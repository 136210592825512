import { Component, OnInit } from '@angular/core';
import { MensajesService } from 'src/app/services/mensajes.service';
import { AuthenticateService } from '../../services/authenticate.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { AdministradoresService } from '../../services/administradores.service'
@Component({
  selector: 'app-administradores',
  templateUrl: './administradores.component.html',
  styleUrls: ['./administradores.component.css']
})
export class AdministradorComponent implements OnInit {

  admin: any = {};

  constructor(private msjSvc: MensajesService,
              private authSvc: AuthenticateService,
              private db: AngularFirestore,
              private adminSvc: AdministradoresService) { }

  ngOnInit(): void {
  }

  addAdmin(adminForm){
    if(adminForm.valid){
      if(adminForm.form.value.contrasena == adminForm.form.value.rep_contrasena){
        this.adminSvc.getAdminByEmail(adminForm.form.value.email).subscribe((administradores: any) => {
          if(administradores.length != 0){
            const admin = {email: adminForm.form.value.email};
            this.adminSvc.addAdmin(admin).subscribe(result => {
              this.msjSvc.mensajeCorrecto('Agregado', 'Administrador agregado correctamente')
            })
          }
          else {
            
            this.msjSvc.mensajeError('Error', 'No se puede agregar el nuevo administrador')
          }
        })
        // this.db.collection('Administradores', ref => ref.where('email', '==', adminForm.form.value.email)).get().subscribe(administradores => {
        //   if (administradores.empty){
        //     const admin = {email: adminForm.form.value.email};
        //     this.db.collection('Administradores').add(admin).then(result => {
        //       this.authSvc.createUserAuth(adminForm.form.value.email, adminForm.form.value.contrasena).then(result => {
        //         this.msjSvc.mensajeCorrecto('Agregar', 'Administrador agregado correctamente');
        //       })
        //       .catch(err => this.msjSvc.mensajeError('Error', 'Administrador no agregado'));
        //     })
        //     .catch(err => this.msjSvc.mensajeError('Error', 'Administrador no agregado'));
        //   }
        // })
      }
      else {
        this.msjSvc.mensajeError('Error', 'Las contraseñas no coinciden');
      } 
    }
  }
}
