import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { PagosService } from 'src/app/services/pagos.service';
import * as XLSX from 'xlsx'; 
import { Pagos } from 'src/app/models/pagos';
import { AngularFirestore } from '@angular/fire/firestore';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  @ViewChild('agGrid',{ static: true }) agGrid: AgGridAngular;
  public Form=false;
  public FileUrl="";
  
  columnDefs = [
    {headerName: 'Fecha Pago', field: 'Fecha', sortable: true, filter: true},   
    {headerName: 'Stand', field: 'Stand', sortable: true, filter: true},
    {headerName: 'Usuario', field: 'Usuario', sortable: true, filter: true},
    {headerName: 'Valor a pagar', field: 'Valor', sortable: true, filter: true},
    {headerName: 'Valor pagado', field: 'Pago', sortable: true, filter: true},
    {headerName: 'Estado', field: 'Estado', sortable: true, filter: true},
  ];

  rowData=[];
  
  constructor(private pagosService: PagosService,private db: AngularFirestore,private msj: MensajesService) { 

  }

  ngOnInit() {
    this.loadTable();
  }

  loadTable(){
    this.pagosService.getPagos().subscribe(pagos => 
    {
      this.rowData = pagos.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        }
      });
      console.log(this.rowData);
    });
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map( node => node.data );
    const selectedDataStringPresentation = selectedData.map( node => node.id + ' ' + node.Fecha+ ' ' + node.Stand).join(', ');
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  openForm(){
    this.Form=true;
  }

  closeForm(){
    this.Form=false;
  }

  public exportarExcel(): void 
  {
       const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.rowData);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Pagos');
       XLSX.writeFile(wb, "pagos.xlsx");			
  }
}
