import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { MensajesService } from 'src/app/services/mensajes.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx'; 
import { ExpositoresService } from 'src/app/services/expositores.service';

@Component({
  selector: 'app-expositores',
  templateUrl: './expositores.component.html',
  styleUrls: ['./expositores.component.css']
})
export class ExpositoresComponent implements OnInit {
  Expositores: Observable<any[]>;

  expositores: any[] = new Array<any>();
  searchExpositores = [];
  formularioExpositor: any;
  filterValueCodigo: string ;
  filterValueEstado: string ="Activo";
  user:string;
  role:string;
  NumeroExpositores;
  
  private ExpositoresDoc: AngularFirestoreDocument; 
  id: string;

  constructor(
    private db: AngularFirestore,
    private msj: MensajesService,
    private router: Router,
    private expositorSvc: ExpositoresService)
    { 
      this.user=sessionStorage.getItem('User');
      this.role=sessionStorage.getItem('Role');
    }

  ngOnInit() {   
    this.expositores.length = 0;
    
    this.expositorSvc.getExpositoresPorEstado(this.filterValueEstado)
    .subscribe((data: any) => {
      
      data.forEach(item => {
        let expositor = item.data;
        expositor.id = item.id;
        this.expositores.push(expositor);
        this.searchExpositores.push(expositor);
      })
    })
    // this.db.collection('Expositores',ref => ref.where('Estado', '==', this.filterValueEstado)).get().subscribe((expositores) => {
    //   this.NumeroExpositores=expositores.docs.length;
    //   expositores.docs.forEach((item) => {
    //     let expositor = item.data();
    //     expositor.id = item.id;
    //     expositor.ref = item.ref;
    //     this.expositores.push(expositor);
    //     this.searchExpositores.push(expositor);
        
    //   }); 
    // });
  }

  filtrarExpositoresPorCodigo(){
    const expositoresList: any[] = [];
    this.expositorSvc.getExpositoresPorCodigo(this.filterValueCodigo).subscribe((data: any) => {
      this.NumeroExpositores = data.length;
      data.forEach((item) => {
        const expositor = item.data;
        expositor.id = item.id;
        expositoresList.push(expositor);
      })
    })
    // this.db.collection('Expositores',ref => ref.where('Codigo', '==', this.filterValueCodigo.toLowerCase())).get().subscribe((expositores) => {
    //   this.NumeroExpositores=expositores.docs.length;
    //   expositores.docs.forEach((item) => {
    //     const expositor = item.data();
    //       expositor.id = item.id;
    //       expositor.ref = item.ref;
    //       expositoresList.push(expositor);
    //   });
    // });
    this.expositores = expositoresList;
  }

  filtrarExpositoresPorNombre(val){
    val = val.toString();
    this.expositores.splice(0, this.expositores.length);
    let temp = this.searchExpositores.filter((d) => {
      val = val.toLowerCase();
      return d.Nombres.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.expositores.push(...temp);
  }

  filtrarExpositoresPorTaller(val){
    val = val.toString();
    this.expositores.splice(0, this.expositores.length);
    let temp = this.searchExpositores.filter((d) => {
      val = val.toLowerCase();
      return d.NombreTaller.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.expositores.push(...temp);
  }

  filtrarExpositoresPorEstado(){
    const expositoresList: any[] = [];
    this.expositorSvc.getExpositoresPorEstado(this.filterValueEstado)
    .subscribe((data: any) => {
      
      data.forEach(item => {
        const expositor = item.data;
        expositor.id = item.id;
        expositoresList.push(expositor)
      })
    })
    // this.db.collection('Expositores',ref => ref.where('Estado', '==', this.filterValueEstado)).get().subscribe((expositores) => {
    //   this.NumeroExpositores=expositores.docs.length;
    //   expositores.docs.forEach((item) => {
    //     const expositor = item.data();
    //       expositor.id = item.id;
    //       expositor.ref = item.ref;
    //       expositoresList.push(expositor);
    //   });
    // });
    this.expositores = expositoresList;
  }

  delete(id) {
    if(confirm("Esta seguro de eliminar el expositor?"))
    {
      this.ExpositoresDoc = this.db.doc('Expositores/' + id);
      this.ExpositoresDoc.delete();
      this.db.doc('Expositores/' + this.id).delete().then(() => {
      this.msj.mensajeCorrecto('Eliminado', 'Expositor eliminado correctamente');
     });
    }
  }

  exportCSV(){
    let result:any[]=[];
    this.db.collection('Expositores').get().subscribe((expositores) => {

      expositores.docs.forEach((item) => {
        const expositor = item.data();
          expositor.id = item.id;
          expositor.ref = item.ref;
          result.push(expositor);
      });


      const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(result);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Expositores');
      XLSX.writeFile(wb, "expositores.xlsx");	 
      /**for (let indexexpositores = 0; indexexpositores < this.expositores.length; indexexpositores++) {
            result.push(object);
                  
        }**/
      }); 
      
  }
}
