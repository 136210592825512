import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministradoresService {
  private URL = `${endpoint}/admins`;

  constructor(private http: HttpClient) { }

  getAdmins(){
    return this.http.get(this.URL)  
  }

  getAdminByEmail(email){
    return this.http.get(`${this.URL}/${email}`)
  }

  addAdmin(admin){
    return this.http.post(`${this.URL}`, admin);
  }
}
