import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-expositoreswebpage',
  templateUrl: './expositoreswebpage.component.html',
  styleUrls: ['./expositoreswebpage.component.scss']
})
export class ExpositoreswebpageComponent implements OnInit {

  expositores: any[] = new Array<any>();
  productos: any[] = new Array<any>();
  expositor:any;
  filterValue: string ;
  id: string;
  closeResult: string;
  categoria:string;
  inicio:boolean=true;
  options = [];
  imageObject: Array<object> = [{}];
  
  constructor(public auth: AngularFireAuth,private db: AngularFirestore,private modalService: NgbModal,private route: ActivatedRoute)
  {

  }

  ngOnInit() {
    this.getCategorias();
    this.route.queryParams.subscribe(params => {
        this.categoria = params['categoria'];
        if(this.categoria!=undefined)
        {
          this.searchCategoria(this.categoria);
        }
    });
  }

  getCategorias(){
    this.db.collection('Parametros').get().subscribe((parametros) => {
      console.log("Productos encontrados");
      console.log(parametros.docs.length);       
      parametros.docs.forEach((item) => {
        const parametro = item.data();    
        this.options=parametro.Categorias;
      });
    });
  }


  verInformacion(expositor){
    this.inicio=false;
    console.log(expositor);
    this.expositor=expositor;
    this.expositor.whatsapp="https://wa.me/57"+this.expositor.TelefonoMovil;
    this.db.collection('Productos',ref => ref.where('User', '==', expositor.Email)).get().subscribe((productos:any) => {
      this.imageObject=[];
      console.log("Productos encontrados");
      console.log(productos.docs.length);
      productos.docs.forEach((item) => {
        const producto = item.data();
        if(producto.User==expositor.Email){
          console.log(producto);
          producto.id=item.id;
          this.productos.push(producto);
          this.imageObject.push({
            image: producto.ImgUrl,
            thumbImage: producto.ImgUrl,
            alt: "Descripcion: " + producto.Descripcion,
            title: producto.Nombre +" Valor: " + producto.Valor + " COP" , 
          })
        } 
        console.log(this.imageObject);
      });
    });
  }

  open(content,expositor) {
    this.verInformacion(expositor);
 
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  searchCategoria(categoria){
    console.log(categoria);
    this.options.forEach((item) => {
      if(item.value==categoria){
        item.class="active";
      }
      else
      {
        item.class="";
      }
    });
    this.expositores=[];
      this.expositores.length = 0;
       this.db.collection('Expositores',ref => ref.where('LineaProducto1', '==', categoria).where('Estado', '==', 'Activo')).get().subscribe((expositores) => {
        console.log(expositores.docs.length);
        expositores.docs.forEach((item) => {
            let expositor = item.data();
            expositor.id = item.id;
            expositor.ref = item.ref;
            if(expositor.ImgUrl==""){
              expositor.ImgUrl="/assets/MPU.jpg"
            }
            this.expositores.push(expositor);
        }); 
      });
   }

  enviarUrlExterna(urlExterna) {
    location.href=urlExterna;   
  }
}
