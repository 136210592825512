import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-expositoresservicios',
  templateUrl: './expositoresservicios.component.html',
  styleUrls: ['./expositoresservicios.component.css']
})

export class ExpositoresServiciosComponent implements  OnInit {
  formularioExpositoresServicio: FormGroup;
  ImgUrl:string="";
  user:string="";
  expositor:string=""; 
  role:string="";
  arrayExpositoresServicios:String[]=[];
  arrayServicios:String[]=[];
  arrayExpositores:String[]=[];
  ServicioForm=false;
  editServicioId="";
  porcentajeSubida=0;
  
  constructor(private formBuilder: FormBuilder,private storage: AngularFireStorage,private db: AngularFirestore,private msj: MensajesService,private activeRoute: ActivatedRoute) { 
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    this.validate();
    this.loadServicios();
    this.loadExpositores();
    this.loadTable();
  }

  public loadTable(){
    this.arrayExpositoresServicios=[];
    this.db.collection('ExpositoresServicios').get().subscribe((ExpositoresServicios:any) => {
      ExpositoresServicios.docs.forEach((item) => {
        const ExpositorServicio = item.data();
        ExpositorServicio.id=item.id;
        
        this.arrayServicios.forEach((servicio:any) => {
          if(servicio.id==ExpositorServicio.Servicio)
          ExpositorServicio.NombreServicio=servicio.Nombre;
        });

        this.arrayExpositores.forEach((expositor:any) => {
          if(expositor.id==ExpositorServicio.Expositor)
          ExpositorServicio.NombreExpositor=expositor.Nombres;
        });

        this.arrayExpositoresServicios.push(ExpositorServicio);
      });
    });
  }

  public loadServicios(){
    this.arrayServicios=[];
    this.db.collection('Servicios').get().subscribe((Servicios:any) => {
      Servicios.docs.forEach((item) => {
        const Servicio = item.data();
        Servicio.id=item.id;
        this.arrayServicios.push(Servicio);
      });
    });
  }

  public loadExpositores(){
    this.arrayExpositores=[];
    this.db.collection('Expositores').get().subscribe((Expositores:any) => {
      Expositores.docs.forEach((item) => {
        const Expositor = item.data();
        Expositor.id=item.id;
        this.arrayExpositores.push(Expositor); 
      });
    });
  }

  validate(){
    this.formularioExpositoresServicio = this.formBuilder.group({
      Fecha: ['', Validators.required],
      Expositor: ['', Validators.required],
      Servicio: ['', Validators.required],
      Detalle: ['',Validators.required],
      Valor: ['', Validators.required],
      Estado: ['', Validators.required],
      ImgUrl: ['',Validators.required],
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    { 
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if (archivo.size < 512000)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'ExpositoresServicios/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.ImgUrl = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'La imagen excede 512 KB');      
        }
    }
  }

  addExpositorServicio()
  {
    this.formularioExpositoresServicio.value.ImgUrl = this.ImgUrl;
    this.formularioExpositoresServicio.value.User=this.user;
    this.formularioExpositoresServicio.value.FechaCreacion = new Date();
    this.formularioExpositoresServicio.value.UsuarioCreacion = this.user;
    this.db.collection('ExpositoresServicios').add(this.formularioExpositoresServicio.value).then((item) => {
      this.msj.mensajeCorrecto('Agregar', 'Expositor servicio agregado correctamente');
      this.ImgUrl="";
      this.porcentajeSubida=0;
      this.formularioExpositoresServicio.reset();
      this.loadTable();
    });
  }

  editExpositorServicio()
  {
    this.db.collection('ExpositoresServicios').get().subscribe((ExpositoresServicios:any) => {
      ExpositoresServicios.docs.forEach((item) => {
        const ExpositorServicio = item.data();
        if(item.id==this.editServicioId)
        {
            ExpositorServicio.Fecha=this.formularioExpositoresServicio.value.Fecha;
            ExpositorServicio.Expositor=this.formularioExpositoresServicio.value.Expositor,
            ExpositorServicio.Servicio=this.formularioExpositoresServicio.value.Servicio;
            ExpositorServicio.Detalle=this.formularioExpositoresServicio.value.Detalle;
            ExpositorServicio.Valor=this.formularioExpositoresServicio.value.Valor;
            ExpositorServicio.Estado=this.formularioExpositoresServicio.value.Estado;
            ExpositorServicio.ImgUrl=this.ImgUrl;
           this.db.doc('ExpositoresServicios/' + item.id).update(ExpositorServicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Servicio actualizado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el elemento');
            });
        }
      });
    });    
  }

  enableExpositorServicio(value)
  {
    this.db.collection('ExpositoresServicios').get().subscribe((ExpositoresServicios:any) => {
      ExpositoresServicios.docs.forEach((item) => {
        const ExpositorServicio = item.data();
        if(item.id==value)
        {
          ExpositorServicio.Estado="ACTIVO";
          this.db.doc('ExpositoresServicios/' + item.id).update(ExpositorServicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'ExpositorServicio inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el ExpositorServicio');
            });
        }
      });
    });    
  }

  disableExpositorServicio(value)
  {
    this.db.collection('ExpositoresServicios').get().subscribe((ExpositoresServicios:any) => {
      ExpositoresServicios.docs.forEach((item) => {
        const ExpositorServicio = item.data();
        if(item.id==value)
        {
          ExpositorServicio.Estado="INACTIVO";
          this.db.doc('ExpositoresServicios/' + item.id).update(ExpositorServicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'ExpositorServicio inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el ExpositorServicio');
            });
        }
      });
    });    
  }


  deleteExpositorServicio(value)
  {
    this.db.collection("ExpositoresServicios").doc(value).delete().then((item)=> {      
      this.msj.mensajeCorrecto('Eliminar', 'Expositores servicio eliminado correctamente');
      this.loadTable();        
    });
  }

  editExpositorServicioForm(value){
    this.editServicioId=value;
    this.db.collection('ExpositoresServicios').get().subscribe((ExpositoresServicios:any) => {
      ExpositoresServicios.docs.forEach((item) => {
        const ExpositorServicio = item.data();
        if(item.id==this.editServicioId)
        {
          this.ImgUrl=ExpositorServicio.ImgUrl;
          this.formularioExpositoresServicio.setValue({
            'Fecha':ExpositorServicio.Fecha,
            'Expositor':ExpositorServicio.Expositor,
            'Servicio':ExpositorServicio.Servicio,
            'Detalle':ExpositorServicio.Detalle,
            'Valor':ExpositorServicio.Valor,
            'Estado':ExpositorServicio.Estado,
            'ImgUrl':ExpositorServicio.ImgUrl,
          });
        }
      });
    });
    this.ServicioForm=true;
  }

  createServicioForm(){
    this.editServicioId="";
    this.ServicioForm=true;
  }

  closeServicioForm(){
    this.editServicioId="";
    this.ServicioForm=false;
  }
}
