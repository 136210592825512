import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private URL = `${endpoint}/banner`;
  constructor(private http: HttpClient) { }

  getBanners(){
    return this.http.get(this.URL)
  }

  addBanner(data){
    return this.http.post(`${this.URL}`, data)
  }

  updateBanner(id, data){
    return this.http.put(`${this.URL}/${id}`, data)
  }

  deleteBanner(id){
    return this.http.delete(`${this.URL}/${id}`)
  }
  
}
