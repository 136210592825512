import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})

export class ServiciosComponent implements  OnInit {
  formularioServicio: FormGroup;
  ImgUrl:string="";
  user:string="";
  expositor:string=""; 
  role:string="";
  arrayServicios:String[]=[];
  porcentajeSubida=0;  
  ServicioForm=false;
  editServicioId="";
  
  constructor(private formBuilder: FormBuilder,private storage: AngularFireStorage,private db: AngularFirestore,private msj: MensajesService) { 
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    console.log("user and role");
    console.log(this.user);
    console.log(this.role);
    this.validate();
    this.loadTable();
  }

  public loadTable(){
    this.arrayServicios=[];
    this.db.collection('Servicios').get().subscribe((Servicios:any) => {
      Servicios.docs.forEach((item) => {
        const Servicio = item.data();
        Servicio.id=item.id;
        this.arrayServicios.push(Servicio); 
      });
    });
  }

  validate(){
    this.formularioServicio = this.formBuilder.group({
      Nombre: ['', Validators.required],
      Descripcion: ['',Validators.required],
      Valor: ['', Validators.required],
      Estado: ['', Validators.required],
      FechaCreacion: [''],  
      ImgUrl: ['',Validators.required],
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    { 
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if (archivo.size < 512000)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Servicios/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.ImgUrl = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'La imagen excede 512 KB');      
        }
    }
  }

  addServicio()
  {
    this.formularioServicio.value.ImgUrl = this.ImgUrl;   
    this.formularioServicio.value.FechaCreacion = new Date();
    this.formularioServicio.value.UsuarioCreacion = this.user;
    this.db.collection('Servicios').add(this.formularioServicio.value).then((item) => {
      this.msj.mensajeCorrecto('Agregar', 'Servicio agregado correctamente');
      this.ImgUrl="";
      this.porcentajeSubida=0;
      this.formularioServicio.reset();
      this.loadTable();
    });
  }

  editServicio()
  {
    this.db.collection('Servicios').get().subscribe((Servicios:any) => {
      Servicios.docs.forEach((item) => {
        const Servicio = item.data();
        console.log(item);
        console.log(this.editServicioId);
        if(item.id==this.editServicioId)
        {
            console.log(Servicio);
            Servicio.Nombre=this.formularioServicio.value.Nombre;
            Servicio.Descripcion=this.formularioServicio.value.Descripcion,
            Servicio.Valor=this.formularioServicio.value.Valor;
            Servicio.Estado=this.formularioServicio.value.Estado;
            Servicio.ImgUrl=this.ImgUrl;
           this.db.doc('Servicios/' + item.id).update(Servicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Servicio actualizado correctamente');
            this.loadTable();
            console.log(Servicio);
            }).catch(error => {
              console.log(error);
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el elemento');
            });
        }
      });
    });    
  }

  enableServicio(value)
  {
    this.db.collection('Servicios').get().subscribe((Servicio:any) => {
      Servicio.docs.forEach((item) => {
        const Servicio = item.data();
        console.log(Servicio);
        if(item.id==value)
        {
          Servicio.Estado="ACTIVO";
          this.db.doc('Servicios/' + item.id).update(Servicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Servicio inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              console.log(error);
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el Servicio');
            });
        }
      });
    });    
  }

  disableServicio(value)
  {
    this.db.collection('Servicios').get().subscribe((Servicio:any) => {
      Servicio.docs.forEach((item) => {
        const Servicio = item.data();
        if(item.id==value)
        {
          Servicio.Estado="INACTIVO";
          this.db.doc('Servicios/' + item.id).update(Servicio).then((resultado) => {
            this.msj.mensajeCorrecto('Editado', 'Servicio inactivado correctamente');
            this.loadTable();
            }).catch(error => {
              console.log(error);
              this.msj.mensajeError('Error', 'Se ha producido un error al editar el Servicio');
            });
        }
      });
    });    
  }

  deleteServicio(value)
  {
    this.db.collection("Servicios").doc(value).delete().then((item)=> {      
      this.msj.mensajeCorrecto('Eliminar', 'Servicio eliminado correctamente');
      this.loadTable();        
    });
  }

  editServicioForm(value){
    this.editServicioId=value;
    this.db.collection('Servicios').get().subscribe((Servicios:any) => {
      Servicios.docs.forEach((item) => {
        const Servicio = item.data();
        if(item.id==this.editServicioId)
        {
          console.log(Servicio);
          this.ImgUrl=Servicio.ImgUrl;
          this.formularioServicio.setValue({
            'Nombre':Servicio.Nombre,
            'Descripcion':Servicio.Descripcion,
            'Valor':Servicio.Valor,
            'Estado':Servicio.Estado,
            'FechaCreacion':Servicio.FechaCreacion,
            'ImgUrl':Servicio.ImgUrl,
          });
        }
      });
    });
    this.ServicioForm=true;
  }

  createServicioForm(){
    this.editServicioId="";
    this.ServicioForm=true;
  }

  closeServicioForm(){
    this.editServicioId="";
    this.ServicioForm=false;
  }
}
