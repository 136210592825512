import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExpositoresComponent } from './components/expositores/expositores.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MensajesService } from './services/mensajes.service';
import { ExpositorComponent } from './components/expositor/expositor.component';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';


// Routes
import { app_routing } from './app.routes';
import { AngularFirestore } from '@angular/fire/firestore';
import { StandsComponent } from './components/stands/stands.component';
import { HomeComponent } from './components/home/home.component';
import { ProductosComponent } from './components/productos/productos.component';
import { BannerComponent } from './components/banner/banner.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { OrdenesComponent } from './components/ordenes/ordenes.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { ExpositoresServiciosComponent } from './components/expositoresservicios/expositoresservicios.component';
import { AgGridModule } from 'ag-grid-angular';
import { AuthenticateService } from './services/authenticate.service';
import { CarguemasivoComponent } from './components/carguemasivo/carguemasivo.component';
import { ExpositoreswebpageComponent } from './components/expositoreswebpage/expositoreswebpage.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { AdministradorComponent } from './components/administradores/administradores.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarComponent } from './components/sidebar/sidebar.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    LogoutComponent,
    ExpositoresComponent,
    ExpositorComponent,
    StandsComponent,
    HomeComponent,
    OrdenesComponent,
    ProductosComponent,
    BannerComponent,
    ServiciosComponent,
    PagosComponent,
    MapaComponent,
    ExpositoresServiciosComponent,
    CarguemasivoComponent,
    ExpositoreswebpageComponent,
    CategoriasComponent,
    AdministradorComponent,
    ForgotPasswordComponent,
    SidebarComponent  
  ],
  imports: [
    app_routing,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ProgressbarModule.forRoot(),
    AngularFireStorageModule,
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAih0wCNooFDJ4L2dI0oGKf35qwdgaP4SA',
      libraries: []
    }),
    NgbModule,
    NgImageSliderModule,
    FontAwesomeModule
  ],
  providers: [
    AngularFireAuth,
    AngularFirestore,
    MensajesService,
    AuthenticateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
