import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  private URL = `${endpoint}/categorias`
  constructor(private http: HttpClient) { }

  getCategorias(){
    return this.http.get(`${this.URL}`)
  }

  addCategoria(data){
    return this.http.post(`${this.URL}`, data)
  }

  updateCategoria(id, data){
    return this.http.put(`${this.URL}/${id}`, data)
  }

  deleteCategoria(id){
    return this.http.delete(`${this.URL}/${id}`)
  }

  

}
