import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public get currentRol(){
    return this.authService.currentRole;
  }

  public get currentUser(){
    return this.authService.currentUser;
  }
  constructor(private authService: AuthenticateService) { }

  ngOnInit(): void {
    
  }

}
