import { InfoWindowManager } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formForgotPassword: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authSvc: AuthenticateService) { }

  ngOnInit(): void {
    this.formForgotPassword = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    })
  }
  sendLinkForPassword(){
    if(this.formForgotPassword.valid)
    {
      this.authSvc.forgetPassword(this.formForgotPassword.value.email)
      .then(() => {
        window.alert('Revise su correo electronico para cambiar la contraseña')
      })
      .catch(error => {
        window.alert(error)
      })
    }
  }
}
