import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoint } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ExpositoresService {
  private URL = `${endpoint}/expositores`;
  constructor(private http: HttpClient) { }

  getExpositoresPorEstado(estado){
    return this.http.get(`${this.URL}/estado/${estado}`)
  }

  getExpositoresPorCodigo(codigo){
    return this.http.get(`${this.URL}/codigo/${codigo}`)
  }

  getExpositores(){
    return this.http.get(`${this.URL}`)
  }

  addExpositor(data){
    return this.http.post(`${this.URL}`, data)
  }

  updateExpositor(id, data){
    return this.http.put(`${this.URL}/${id}`, data)
  }

  deleteExpositor(id){
    return this.http.delete(`${this.URL}/${id}`)
  }

}
