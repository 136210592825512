import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-expositor',
  templateUrl: './expositor.component.html',
  styleUrls: ['./expositor.component.css']
})
export class ExpositorComponent implements OnInit {
  formularioExpositor: FormGroup;
  porcentajeSubida = 0;
  urlImage = '';
  edit:boolean;
  enableEmail:boolean;
  id: string; 
  user:string;
  role:string;
  isNameSelected: boolean;
  EstadoHabilitado:string='';
  categorias=[];
  

  constructor(private formBuilder: FormBuilder,private storage: AngularFireStorage,private db: AngularFirestore,private activeRoute: ActivatedRoute,private msj: MensajesService,private router: Router)
  { 
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  selectInput(event) {
    let selected = event.target.value;
    if (selected == "Suspendido") {
      this.isNameSelected = true;
    } else {
      this.isNameSelected = false;
    }
  }

  ngOnInit() {
    this.getCategorias();
    this.validate();
    this.id = this.activeRoute.snapshot.params.expositorID;
     if (this.id != undefined && this.role=='Admin')
    {
      this.enableEmail = false;
      this.edit=true;
      this.db.doc<any>('Expositores' + '/' + this.id).valueChanges().subscribe((expositor) =>{
        this.setForm(expositor);        
      });
    }

    if (this.id == undefined && this.role=='Expositor')
    {
      
      this.enableEmail = false;      
      this.edit = true;
      let email =this.user.toLowerCase().trim();
      
      
      this.db.collection('Expositores',ref => ref.where('Email', '==', email)).get().subscribe((expositores) => {
        
        
        expositores.docs.forEach((item) => {
            const expositor = item.data();
            
            this.id=item.id;
            this.setForm(expositor);
        });
      });
      this.formularioExpositor.controls['Estado'].disable();
      this.formularioExpositor.controls['MotivoSuspension'].disable();
    }
    
    if (this.id == undefined && this.role=='Admin')
    {
      
      this.enableEmail = true;      
      this.edit = false;
    }
    
  }

  addExpo()
  {
    let exist=false;        
    this.db.collection('Expositores').get().subscribe((expositores) => {
      expositores.docs.forEach((item) => {
        const expositor = item.data();
        if (expositor.Email== this.formularioExpositor.value.Email){      
          exist=true;
        }
      });

      if(!exist){
        this.formularioExpositor.value.ImgUrl = this.urlImage;
        this.formularioExpositor.value.FechaCreacion = new Date();
        this.formularioExpositor.value.UsuarioCreacion = sessionStorage.getItem('User');
            this.formularioExpositor.value.FechaNacimiento = new Date(this.formularioExpositor.value.FechaNacimiento);
            this.db.collection('Expositores').add(this.formularioExpositor.value).then((termino) => {
              this.msj.mensajeCorrecto('Agregar', 'Expositor agregado correctamente');
              this.formularioExpositor.reset();
            });
            this.db.collection('HistorialExpositores').add(this.formularioExpositor.value).then((termino) => {
            });
            
      }
      else
      {
        this.msj.mensajeError('Error', 'Ya existe un expositor con esa cuenta de email.');     
      }
    });
  }

  editExpo()
  {
    this.formularioExpositor.value.ImgUrl = this.urlImage;
    this.formularioExpositor.value.FechaNacimiento = new Date(this.formularioExpositor.value.FechaNacimiento);
    this.formularioExpositor.value.FechaActualizacion = new Date();
    this.formularioExpositor.value.UsuarioActualizacion = sessionStorage.getItem('User');
  
    this.db.doc('Expositores/' + this.id).update(this.formularioExpositor.value).then((resultado) => {
    this.msj.mensajeCorrecto('Editado', 'Expositor editado correctamente');
    }).catch(error => {
      this.msj.mensajeError('Error', 'Se ha producido un error al editar el Expositor');
    });

    this.db.collection('HistorialExpositores').add(this.formularioExpositor.value).then((termino) => {
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    { 
      let nombre = new Date().getTime().toString();
      let archivo = event.target.files[0];
      if (archivo.size < 1024000)
      {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Expositores/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.urlImage = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
      }
      else
      {
        this.msj.mensajeError('Agregar', 'La imagen excede 1 MB'); 
      }
    }
  }

  validate(){
    this.formularioExpositor = this.formBuilder.group({
      Estado: [''],
      MotivoSuspension: [''],
      Email: ['', Validators.required],
      Nombres: ['', Validators.required],
      Apellidos: ['', Validators.required],
      Descripcion: [''],
      Codigo: ['', Validators.required],
      Condicion:[''],
      Identificacion: ['', Validators.required],
      TipoIdentificacion: ['', Validators.required],
      Edad: [''],
      Sexo: [''],
      FechaNacimiento: [''],
      LugarNacimiento: [''],
      EstadoCivil: [''],
      NumeroHijos: [''],
      PersonasCargo: [''],
      EstratoSocioeconomico: [''],
      GrupoEtnico: [''],
      SectorSocial: [''],
      FormacionAcademica: [''],
      UltimoTituloObtenido: [''],
      Direccion: [''],
      Ciudad: [''],
      Localidad: [''],
      Barrio: [''],
      TipoVivienda: [''],
      TelefonoFijo: [''],
      TelefonoMovil: [''],
      NombreContactoEmergencia: [''],
      TelefonoContactoEmergencia: [''],
      VinculadoSalud: [''],
      RegimenSalud: [''],
      EPS: [''],
      TipoSangre: [''],
      NombreTaller: [''],
      AnoCreacionTaller: [''],
      AnoIngresoMercado: [''],
      PersonasVinculadasTaller: [''],
      UbicacionTaller: [''],
      Nit: [''],
      CamaraComercio: [''],
      IngresosMensualesTaller: [''],
      PaginaWeb: [''],
      FacebookUrl: [''],
      InstagramUrl: [''],
      ImgUrl: ['', Validators.required],
      LineaProducto1: [''],
      LineaProducto2: [''],
      LineaProducto3: [''],
      LineaProducto4: [''],
      LineaProducto5: [''],
      LineaProducto6: ['']
    });
  }

  setForm(expositor){
    if(expositor.Estado=="Suspendido"){
      this.isNameSelected=true;
    }

    this.formularioExpositor.patchValue({
      Estado:expositor.Estado==undefined ? "Activo":expositor.Estado,
      MotivoSuspension:expositor.MotivoSuspension==undefined ? " ":expositor.MotivoSuspension,
      Email: expositor.Email,
      Nombres: expositor.Nombres,
      Apellidos: expositor.Apellidos,
      Descripcion: expositor.Descripcion,
      Codigo: expositor.Codigo,
      Condicion:expositor.Condicion,
      Identificacion: expositor.Identificacion,
      TipoIdentificacion: expositor.TipoIdentificacion,
      Edad: expositor.Edad,
      Sexo: expositor.Sexo,
      FechaNacimiento:  (expositor.FechaNacimiento) ? new Date (expositor.FechaNacimiento.seconds * 1000).toISOString().substr(0,10): null,
      LugarNacimiento: expositor.LugarNacimiento,
      EstadoCivil: expositor.EstadoCivil,
      NumeroHijos: expositor.NumeroHijos,
      PersonasCargo: expositor.PersonasCargo,
      EstratoSocioeconomico: expositor.EstratoSocioeconomico,
      GrupoEtnico: expositor.GrupoEtnico,
      SectorSocial: expositor.SectorSocial,
      FormacionAcademica: expositor.FormacionAcademica,
      UltimoTituloObtenido: expositor.UltimoTituloObtenido,
      Direccion: expositor.Direccion,
      Ciudad: expositor.Ciudad,
      Localidad: expositor.Localidad,
      Barrio: expositor.Barrio,
      TipoVivienda: expositor.TipoVivienda,
      TelefonoFijo: expositor.TelefonoFijo,
      TelefonoMovil: expositor.TelefonoMovil,
      NombreContactoEmergencia: expositor.NombreContactoEmergencia,
      TelefonoContactoEmergencia: expositor.TelefonoContactoEmergencia,
      VinculadoSalud: expositor.VinculadoSalud,
      RegimenSalud: expositor.RegimenSalud,
      EPS: expositor.EPS,
      TipoSangre: expositor.TipoSangre,
      NombreTaller: expositor.NombreTaller,
      AnoCreacionTaller: expositor.AnoCreacionTaller,
      AnoIngresoMercado: expositor.AnoIngresoMercado,
      PersonasVinculadasTaller: expositor.PersonasVinculadasTaller,
      UbicacionTaller:expositor.UbicacionTaller,
      Nit: expositor.Nit,
      CamaraComercio: expositor.CamaraComercio,
      IngresosMensualesTaller: expositor.IngresosMensualesTaller,
      PaginaWeb: expositor.PaginaWeb,
      FacebookUrl: expositor.FacebookUrl,
      InstagramUrl: expositor.InstagramUrl,
      ImgUrl: '',
      LineaProducto1: expositor.LineaProducto1,
      LineaProducto2: expositor.LineaProducto2,
      LineaProducto3: expositor.LineaProducto3,
      LineaProducto4: expositor.LineaProducto4,
      LineaProducto5: expositor.LineaProducto5,
      LineaProducto6: expositor.LineaProducto6
      });
    this.urlImage = expositor.ImgUrl;
  }

  getCategorias(){
    this.db.collection('Parametros').get().subscribe((parametros) => {
      
             
      parametros.docs.forEach((item) => {
        const parametro = item.data();    
        this.categorias=parametro.Categorias;
      });
    });
  }
}
