import { Component, OnInit, ViewChild } from '@angular/core';
import { Pagos } from 'src/app/models/pagos';
import { AngularFirestore } from '@angular/fire/firestore';
import { MensajesService } from 'src/app/services/mensajes.service';
import { Expositores } from 'src/app/models/expositores';

@Component({
  selector: 'app-carguemasivo',
  templateUrl: './carguemasivo.component.html',
  styleUrls: ['./carguemasivo.component.scss']
})
export class CarguemasivoComponent implements OnInit {
  @ViewChild('csvReader') csvReader: any;  
  public records: any[] = []; 
  public msjCargue: string; 
  public arrayProductos=[];   
  public arrayProductosActualizar=[];  
  constructor(private db: AngularFirestore,private msj: MensajesService) { }

  ngOnInit() {
  }

  guardar()
  {
    let cont = 0;
    if(this.records){  
      this.records.forEach((item) => {
        cont++
        var myJSON = JSON.stringify(item);
              this.db.collection("Expositores").add(JSON.parse(myJSON)).then((termino) => {
              if(cont<this.records.length)
              {
                this.msjCargue="cargando...";
              }
              else
              {
                this.msjCargue="Finalizado";
                this.msj.mensajeCorrecto("Cargue masivo","Cargue completado");
              }
        });
      });
    }
  }

  cargar($event: any): void {  
  
    let text = [];  
    let files = $event.srcElement.files; 
    console.log(files); 
  
    if (this.isValidCSVFile(files[0])) {  
  
      let input = $event.target;  
      let reader = new FileReader();  
      reader.readAsText(input.files[0]);  
  
      reader.onload = () => {  
        let csvData = reader.result;  
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);  
        let headersRow = this.getHeaderArray(csvRecordsArray);    
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);  
        console.log("records");
        console.log(this.records);
      };  
  
      reader.onerror = function () {  
        console.log('error is occured while reading file!');  
      };  
  
    } else {  
      alert("Please import valid .csv file.");  
      this.fileReset();  
    }  
  }  
  
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {  
    let csvArr = [];  
  
    for (let i = 1; i < csvRecordsArray.length; i++) {  
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');  
      if (curruntRecord.length == headerLength) {  
        let csvRecord: Pagos = new Pagos();  
        csvRecord.id = curruntRecord[0].trim();  
        csvRecord.fechaPago = curruntRecord[1].trim();  
        csvRecord.valor = curruntRecord[2].trim();  
        csvArr.push(this.map(curruntRecord,"Expositores"));  
      }  
    }  
    return csvArr;  
  }  
  
  map(curruntRecord,type){
    switch (type){
      case "Pagos":
        let csvRecordPagos: Pagos = new Pagos();  
        csvRecordPagos.id = curruntRecord[0].trim();  
        csvRecordPagos.fechaPago = curruntRecord[1].trim();  
        csvRecordPagos.valor = curruntRecord[2].trim();
        return csvRecordPagos;
        break;
      case "Expositores":
        let csvRecordExpositores: Expositores = new Expositores();  
        csvRecordExpositores.Email=curruntRecord[18];
        csvRecordExpositores.Nombres= curruntRecord[3];
        csvRecordExpositores.Apellidos= curruntRecord[4];
        csvRecordExpositores.Descripcion= "";
        csvRecordExpositores.Codigo= curruntRecord[6];
        csvRecordExpositores.Condicion=curruntRecord[7];
        csvRecordExpositores.Identificacion= curruntRecord[5];
        csvRecordExpositores.TipoIdentificacion= "";
        csvRecordExpositores.Edad= curruntRecord[11];
        csvRecordExpositores.Sexo= curruntRecord[20];
        csvRecordExpositores.LugarNacimiento= curruntRecord[10];
        csvRecordExpositores.EstadoCivil= curruntRecord[21];
        csvRecordExpositores.NumeroHijos= curruntRecord[23];
        csvRecordExpositores.PersonasCargo= curruntRecord[24];
        csvRecordExpositores.EstratoSocioeconomico= curruntRecord[22];
        csvRecordExpositores.GrupoEtnico= curruntRecord[28];
        csvRecordExpositores.SectorSocial= curruntRecord[29];
        csvRecordExpositores.FormacionAcademica= curruntRecord[26];
        csvRecordExpositores.UltimoTituloObtenido= curruntRecord[27];
        csvRecordExpositores.Direccion= curruntRecord[12];
        csvRecordExpositores.Ciudad= curruntRecord[15];
        csvRecordExpositores.Localidad= curruntRecord[14];
        csvRecordExpositores.Barrio= curruntRecord[13];
        csvRecordExpositores.TipoVivienda= curruntRecord[25];
        csvRecordExpositores.TelefonoFijo= curruntRecord[16];
        csvRecordExpositores.TelefonoMovil= curruntRecord[17];
        csvRecordExpositores.NombreContactoEmergencia= curruntRecord[43];
        csvRecordExpositores.TelefonoContactoEmergencia= curruntRecord[44];
        csvRecordExpositores.VinculadoSalud= curruntRecord[19];
        csvRecordExpositores.RegimenSalud= curruntRecord[41];
        csvRecordExpositores.EPS= "";
        csvRecordExpositores.TipoSangre= "";
        csvRecordExpositores.NombreTaller= curruntRecord[30];
        csvRecordExpositores.AnoCreacionTaller= curruntRecord[33];
        csvRecordExpositores.AnoIngresoMercado= curruntRecord[35];
        csvRecordExpositores.PersonasVinculadasTaller= curruntRecord[34];
        csvRecordExpositores.UbicacionTaller=curruntRecord[36];
        csvRecordExpositores.Nit= curruntRecord[32];
        csvRecordExpositores.CamaraComercio= curruntRecord[31];
        csvRecordExpositores.IngresosMensualesTaller= curruntRecord[42];
        csvRecordExpositores.PaginaWeb= curruntRecord[37];
        csvRecordExpositores.FacebookUrl= curruntRecord[38];
        csvRecordExpositores.InstagramUrl= curruntRecord[39];
        csvRecordExpositores.ImgUrl= "https://firebasestorage.googleapis.com/v0/b/ecommerceadmindemo.appspot.com/o/Expositores%2F1595518567525.jpg?alt=media&token=dc4e9638-a392-47b0-897e-31a6c1c1ab12";
        csvRecordExpositores.LineaProducto1= curruntRecord[40];
        csvRecordExpositores.LineaProducto2= "";
        csvRecordExpositores.LineaProducto3= "";
        csvRecordExpositores.LineaProducto4= "";
        csvRecordExpositores.LineaProducto5= "";
        csvRecordExpositores.LineaProducto6= "";
        return csvRecordExpositores;
      default:
        break;
    }
  }

  isValidCSVFile(file: any) {  
    return file.name.endsWith(".csv");  
  }  
  
  getHeaderArray(csvRecordsArr: any) {  
    let headers = (<string>csvRecordsArr[0]).split(',');  
    let headerArray = [];  
    for (let j = 0; j < headers.length; j++) {  
      headerArray.push(headers[j]);  
    }  
    return headerArray;  
  }  
  
  fileReset() {  
    this.csvReader.nativeElement.value = "";  
    this.records = [];  
  }
}
