// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDfe6gz2o1i2KH3WQX-1PC893CYcKs0rXw",
    authDomain: "ecommerceadmindemo.firebaseapp.com",
    databaseURL: "https://ecommerceadmindemo.firebaseio.com",
    projectId: "ecommerceadmindemo",
    storageBucket: "ecommerceadmindemo.appspot.com",
    messagingSenderId: "71050585656",
    appId: "1:71050585656:web:3144f46e7bab3ac1f528cd",
    measurementId: "G-VSENZ4PDN2"
  }
};

export const endpoint = "https://us-central1-ecommerceadmindemo.cloudfunctions.net/app";

if(location.hostname != 'localhost'){
  environment.production = true;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
